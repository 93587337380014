import React from 'react'
import PageContainer from '../../componets/pageContainer'
import { Link } from 'react-router-dom'

function GettingStartedContent() {
    return (
        <div className='page-content'>
            <div className="features-title">
                <div className="icon">G</div>
                <h1>Getting Started</h1>
            </div>
            <div>
                <h3>How to access the platform</h3>
                <p>To access the platform, follow these steps:</p>
            </div>
            <h3>Key steps</h3>
            <div className="features-cards cards">
                <div className="card">
                    <div className="card-title">
                        <div className="icon">A</div>
                        <h3>Access the platform</h3>
                    </div>
                    <p>Visit the platform at <Link to={'https://q-control.csrlimited.com/'} className='page-link'>https://q-control.csrlimited.com/</Link>. We recommend pinning the tab or bookmarking it for easy access in the future</p>
                </div>
                {
                    steps && steps.map((feature) => (
                        <div className="card">
                            <div className="card-title">
                                <div className="icon">{feature.icon}</div>
                                <h3>{feature.title}</h3>
                            </div>
                            <p>{feature.description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}


const GettingStarted = () => {
    return (
        <PageContainer content={<GettingStartedContent />} />
    )
}
export default GettingStarted

const steps = [

    {
        icon: 'C',
        title: 'Login',
        description: 'Enter your CHMC credentials to access the platform'
    },

    {
        icon: 'S',
        title: 'Use the platform',
        description: 'Start using the platform to access and manage your healthcare resources'
    },
    {
        icon: 'N',
        title: 'Navigation',
        description: 'Navigate different features by clicking links in the left side menu, and to header'
    }
]