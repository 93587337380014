import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../assets/css/pages/pageContainer.css'
import { ArrowDown01Icon, ArrowRight01Icon, Link01Icon } from 'hugeicons-react'

function PageContainer({ content, docTitle }) {
    return (
        <div className='page-container'>
            <header className='container'>
                <div className="branding">
                    <h1>CHMC Documentation</h1>
                    <p>Cohesive healthcare management and consulting</p>
                </div>
                <Link to={'https://q-control.csrlimited.com/'} className="button primary-button support-link">
                    Log into quality control
                    <Link01Icon />
                </Link>
            </header>
            <div className="content-container container">
                <SideBar docTitle={docTitle} />
                <div className="content">
                    {content}
                </div>
            </div>
            <footer></footer>
        </div>
    )
}

export default PageContainer


const Feature = ({ activeFeature, activeLink, handleFeatureClick, featureName, links, handleLinkClick, link }) => {
    return (
        <div className={`feature ${activeFeature === featureName ? 'feature-open' : 'feature'}`}>
            <div onClick={() => handleFeatureClick(featureName)} className="feature-title"><span>{featureName}</span> <ArrowRight01Icon className={` ${activeFeature === featureName ? 'rotate' : 'un-rotate'}`} /> </div>
            <div className="feature-elements">
                {
                    links && links.map((link, index) => (

                        <Link to={link.link} key={index} onClick={() => handleLinkClick(link.name)} className={`element ${activeLink === link.name ? 'active' : ''}`}>
                            <div className="dot"></div>
                            <span>{link.name}</span>
                        </Link>
                    ))
                }

            </div>
        </div>

    )
}

const SideBar = ({ docTitle }) => {
    const navigate = useNavigate()
    const [activeFeature, setActiveFeature] = useState(null)
    const [activeLink, setActiveLink] = useState('')

    const handleFeatureClick = (feature) => {
        setActiveFeature(activeFeature === feature ? '' : feature)
        localStorage.setItem('activeFeature', feature)
    }

    const handleLinkClick = (link) => {
        setActiveLink(activeLink === link ? '' : link)
        localStorage.setItem('activeLink', link)

    }
    useEffect(() => {
        const activeFeatureFromStorage = localStorage.getItem('activeFeature')
        const activeLinkFromStorage = localStorage.getItem('activeLink')
        setActiveFeature(activeFeatureFromStorage || 'Introduction')
        setActiveLink(activeLinkFromStorage || 'What is Q-control')

    }, [])

    return (
        <nav className="side-bar">
            <div className="doc title">{docTitle}</div>
            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Introduction'}
                links={[
                    { "name": "What is Q-control", link: '/intro/about/' },
                    { "name": "Requirements", link: '/intro/requirements/' },
                    { "name": "Getting started", link: '/intro/getting-started/' },
                ]}
            />
            {/* <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Authentication'}
                links={[
                    { "name": "Login", link: 'login' },
                    { "name": "Forgot password", link: 'forgot-pass' },
                ]} />

            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Profile'}
                links={[
                    { "name": "Incident reports", link: 'incident-reports' },
                    { "name": "Drafts", link: 'drafts' },
                    { "name": "Documents", link: 'documents' },
                    { "name": "Support tickets", link: 'support-tickets' },
                    { "name": "Edit profile", link: 'edit-profile' },
                    { "name": "Change password", link: 'change-password' },
                ]} />


            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Incident tracking'}
                links={[
                    { "name": "New incident", link: 'new-incident' },
                    { "name": "Modify incident", link: 'modify-incident' },
                    { "name": "Drafts", link: 'drafts' },
                    { "name": "Send to department", link: 'send-to-department' },
                    { "name": "Mark resolved", link: 'mark-resolved' },
                    { "name": "Delete incident", link: 'delete-incident' },
                ]}
            />


            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Complaint'}
                links={[
                    { "name": "New complaint", link: 'new-complaint' },
                    { "name": "Modify complaint", link: 'modify-complaint' },
                    { "name": "Send to department", link: 'send-to-department' },
                    { "name": "Mark resolved", link: 'mark-resolved' },
                    { "name": "Delete complaint", link: 'delete-complaint' },
                ]}
            />
            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Documents'}
                links={[
                    { "name": "New document", link: 'new-document' },
                    { "name": "Delete document", link: 'delete-document' },
                ]}
            />

            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Support tickets'}
                links={[
                    { "name": "New ticket", link: 'new-ticket' },
                    { "name": "Modify ticket", link: 'modify-ticket' },
                    { "name": "Delete ticket", link: 'delete-ticket' },
                    { "name": "Reopen ticket", link: 'reopen-ticket' },
                    { "name": "Close ticket", link: 'close-ticket' },
                ]}
            /> */}

        </nav>
    )
}


