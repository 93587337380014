import React from 'react'
import PageContainer from '../../componets/pageContainer'

function RequirementsContent() {
    return (
        <div className='page-content'>
            <div className="features-title">
                <div className="icon">R</div>
                <h1>Requirements</h1>
            </div>

            <div>
                <h3>What do you need to access the platform?</h3>
                <p>Different users will need different permissions and permissions levels to access content and take actions</p>
            </div>

            <h3>Key requirements</h3>

            <div className="features-cards cards">
                {
                    requirements && requirements.map((requirement) => (
                        <div className="card">
                            <div className="card-title">
                                <div className="icon">{requirement.icon}</div>
                                <h3>{requirement.title}</h3>
                            </div>
                            <p>{requirement.description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const Requirements = () => {
    return (
        <PageContainer content={<RequirementsContent />} />
    )
}
export default Requirements

const requirements = [
    {
        icon: 'A',
        title: 'Authentication',
        description: 'Users need to be from any of CHMC facility or have special access'
    },
    // Add more requirements here
    {
        icon: 'C',
        title: 'Credentials',
        description: 'Users need to provide their CHMC credentials to access the platform'
    },
    {
        icon: 'D',
        title: 'Device',
        description: 'Users need to have a device that can access the internet, such as a smartphone or tablet, or a computer'
    },
    {
        icon: 'A',
        title: 'Accessibility',
        description: 'Users should be able to read and write and should have basic knowledge of working with electronic applications'
    }
]